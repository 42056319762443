.submit {
  width: 100%;
  height: 60px;
  background: rgba(1, 116, 207, 1);
  color: white;

  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  border: 0px;
  cursor: pointer;
}

.book {
  /* width: 100%; */
  /* height: 60px; */
  background: #ffffff;
  color: #0174cf;

  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  border: 0px;
  padding: 15px;
  text-decoration: none;
  z-index: 100;
  transition: all 0.5s;
  border: 1px solid #0174cf;
}

.book:hover {
  color: white;
  background: rgba(1, 116, 207, 1);

  transition: all 0.5s;
}
.submit:active {
  background: rgba(1, 116, 207, 0.5);
}

.roomName {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;

  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));

  text-transform: uppercase;

  color: #0b46c1;
}

.roomDescription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;

  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100)) !important;
  line-height: 24px;

  color: #000000;
}

.roomPrice {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;

  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));

  margin: 0;

  text-transform: uppercase;

  color: #0b46c1;
}

.roomPriceTag {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;

  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  margin: 0;

  text-transform: uppercase;

  color: #0b46c1;
}

.img {
  width: 100%;
}

.cardSection {
  position: relative;
  padding: 20px;
}

.cardSection::before {
  content: "";
  position: absolute;
  border: 1px solid rgba(1, 116, 207, 1);
  height: 65%;
  width: 20%;
  border-bottom: 0;
  border-right: 0;
  left: 0;
  top: 0;
  z-index: -1;
}

.cardSection::after {
  content: "";
  position: absolute;
  border: 1px solid rgba(1, 116, 207, 1);
  height: 100%;
  width: 70%;
  border-left: 0;
  border-top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.bigTitle {
  margin: 0;
  position: absolute;
  bottom: 20%;
  left: 5%;

  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  /*  max(32px, 7vw); */
  font-size: max(calc(96px / 1.8), calc((5.8vw * 96) / 100));

  text-transform: uppercase;

  color: #ffffff;
}

.roomPriceSection {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.roomCardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

@media only screen and (max-width: 768px) {
  .roomCardFooter {
    /* flex-direction: column; */
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .bigTitle {
    margin-left: 50px;
  }
}
