.row {
  display: flex;
  flex-direction: row;
}

/* .title {
  font-family: Roboto Slab;
  font-size: 48px;
  font-weight: 300;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
} */

.title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;

  font-size: max(calc(96px / 1.8), calc((5vw * 96) / 100));
  line-height: 127px;
  /* identical to box height */

  text-transform: uppercase;

  color: #0b46c1;
}

.bigTitle {
  margin: 0;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(128px / 2), calc((5.8vw * 128) / 100));

  text-transform: uppercase;

  color: #0b46c1;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 50px;
}

.serviceSection {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.missionContent {
  max-height: 40%;
  padding: 60px;
  max-width: 60%;
  backdrop-filter: blur(8px);
  background: rgba(25, 49, 99, 0.2);
  margin-right: auto;
  margin-left: 20px;
}

.missionContent > h1 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(128px / 2.5), calc((5.8vw * 128) / 100));

  text-transform: uppercase;

  color: #ffffff;
  margin: 0;
}

.missionContent > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));

  color: #ffffff;
}

.serviceTitle {
  cursor: pointer;

  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(48px / 2.5), calc((5.8vw * 48) / 100));
  line-height: 63px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #0b46c1;
}

.activeTitle {
  text-decoration: underline;
  text-underline-offset: 80px;
  text-decoration-color: #0b46c1;
  text-decoration-thickness: 1px;
}

.serviceImg {
  margin-top: 100px;
}

.serviceImg > img {
  width: 100%;
}

.viewBtn {
  height: 60px;
  width: fit-content;
  border-radius: 0px;
  border-width: 0;
  background: #0b46c1;

  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: white;

  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;

  justify-content: center;

  align-content: center;
  margin: 50px 0px 50px auto;
}

.viewBtn span {
  padding: 0px 20px 0px 20px;
}

.viewBtn img {
  padding: 0px 20px 0px 0px;
  transition: all 0.2s ease-in-out;
}

.viewBtn:hover {
  cursor: pointer;
}

.viewBtn:hover img {
  transform: translateX(15px);
  transition: all 0.2s ease-in-out;
}

.imgsGrid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.galleryBigImg {
  width: auto;
  height: 100%;
  max-width: 99%;
}

.selectedGalleryImg {
  border: 2px solid rgba(11, 70, 193, 1);
}

.gallerySection {
  height: 100vh;
  width: 95vw;
  margin: auto;
  position: relative;
  padding-left: 0px;
}

.gridImg {
  height: 20%;
  width: 100%;
}

.cuisineParagraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));

  color: #000000;
}

.contactWrapper {
  padding: 40px;
}
.contactSection {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  margin: 50px 50px 50px 50px;
  background-color: white;
  display: flex;
}

.contactSection > div:first-child {
  width: 100%;
  flex: 1 / 2;
  padding: 20px;
}

.contactSection > div:first-child > h1 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(64px / 1.8), calc((5.8vw * 64) / 100));

  text-transform: uppercase;

  color: #0174cf;
}

.contactSection > div:nth-child(2) {
  width: 100%;
  flex: 1 / 2;
}

.contactInput,
.contactTextArea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 60px;

  margin-top: 10px;

  border: 1px solid #0174cf;
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0174cf;
  padding-left: 28px;
}
.contactInput::placeholder,
.contactTextArea::placeholder {
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0174cf;
}

.contactTextArea {
  height: 220px;
  padding: 28px;
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0174cf;
  padding-left: 28px;
}

.contactBtn {
  height: 62px;
  width: 235px;
  border: 0px;
  color: white;
  border-radius: 0px;
  background-color: #0174cf;
  font-family: Inter;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  float: right;
  margin-top: 20px;
  border: 2px solid #0174cf;
  transition: all 0.5s;
  cursor: pointer;
}

.contactBtn:hover {
  color: #0174cf;
  background: #ffffff;

  transition: all 0.5s;
}

.hero_section_title_a,
.hero_section_title_b,
.hero_section_title_c {
  color: white;
  margin: 0;
  padding: 0;
}

.hero_section_title_a {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-size: max(2vw, 16px);

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.hero_section_title_b {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(4vw, 32px);

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.hero_section_title_c {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: max(2vw, 16px);

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.missionParagraph > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(2vw, 16px);

  color: #ffffff;
}

.phoneNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phoneNumber > span:nth-child(1) > img {
  width: max(30px, 2vw);
}

.phoneNumber > span:nth-child(2) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(22px, 2vw);
  margin-left: 20px;
  display: flex;
  align-items: center;

  color: #0b46c1;
}

@media only screen and (max-width: 900px) {
  .imgsGrid {
    flex-direction: row;
    align-items: flex-end;
  }

  .gallerySection {
    width: 100%;
  }

  .gridImg {
    width: 20%;
  }

  .galleryBigImg {
    max-width: 100%;
  }

  .contactWrapper {
    padding: 40px 5px 40px 5px;
  }
  .contactSection {
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    margin-bottom: 100px;
  }

  .contactSection > div:first-child {
    flex: 1 !important;
    padding: 0px !important;
  }

  .contactSection > div:nth-child(2) {
    flex: 1 !important;
    min-height: 300px;
  }
  .contactSection > div:nth-child(2) iframe {
    min-height: 300px;
  }

  .contactBtn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .activeTitle {
    text-underline-offset: 10px;
  }
}
