.form {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  margin: 20px;
  position: relative;
  width: 85%;
  /* padding-right: 15%;
  padding-top: 20px; */

  padding: 20px 15% 20px 0px;
}

.input {
  width: -webkit-fill-available;
  margin: 20px 20px 0px 40px;
  padding: 15px;
  border: 1px solid rgba(1, 116, 207, 1);
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));

  line-height: 24px;
  text-transform: uppercase;
  color: #0174cf;
}

.input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  line-height: 24px;

  text-transform: uppercase;

  /* color: #0174cf; */
}

.optionImage {
  margin-right: 65px;
  margin-left: 65px;
  width: 30px;
}

.optionName {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: max(calc(14px / 1.8), calc((5.8vw * 14) / 100));
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
}

.CalendarSection {
  background-color: white;
  /* height: 600px; */
  padding: 20px 50px 20px 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 20px;
}
.CalendarSection > h1 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  line-height: 42px;
  text-transform: uppercase;

  color: #0074cf;
}

.CalendarSection > p {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  line-height: 26px;

  color: #000000;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row > h2 {
  letter-spacing: 0em;

  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  line-height: 48px;
  /* display: flex;
  align-items: flex-end; */
  text-transform: uppercase;

  color: #0174cf;
}

.row > h2 > span {
  font-family: "Inter";
  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(1, 116, 207, 1);
}

.book {
  background: rgba(1, 116, 207, 1);
  color: white;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  border: 0px;
  padding: 15px;
  text-decoration: none;
  z-index: 100;
}

.roomInfoSection {
  border: "1px solid rgba(25, 49, 99, 1)";
  padding: 10px;
  margin: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

.roomCard {
  position: relative;
  padding: 20px;
  margin: 50px;
}

.roomCard::before {
  content: "";
  position: absolute;
  border: 1px solid rgba(1, 116, 207, 1);
  height: 65%;
  width: 20%;
  border-bottom: 0;
  border-right: 0;
  left: 0;
  top: 0;
  z-index: -1;
}

.roomCard::after {
  content: "";
  position: absolute;
  border: 1px solid rgba(1, 116, 207, 1);
  height: 100%;
  width: 70%;
  border-left: 0;
  border-top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.roomCard > h1 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  line-height: 42px;
  text-transform: uppercase;

  color: #0174cf;
}

.roomCard > h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  line-height: 24px;

  color: #000000;
}

.roomCard > h3 {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100));
  line-height: 47px;
  text-transform: uppercase;

  color: #0174cf;
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 50px 20px 40px;
}

.counter > label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #0174cf;
}

.counter > div {
  max-width: 50%;
}
.counter > div > span {
  border: 0px;
  background-color: transparent;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: max(calc(32px / 1.8), calc((5.8vw * 32) / 100));
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #0174cf;
  cursor: pointer;
}

.formFooter {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-end;
}

.formFooter > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  margin: 0px;
  text-transform: uppercase;

  color: #0174cf;
}

.formFooter > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));

  text-transform: uppercase;

  color: #0174cf;
}

.formFooter > input {
  margin: 0px 20px 0px 20px;
}

.formFooter > .submitButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  margin: 0px;
  text-transform: uppercase;

  color: #ffffff;

  width: 270px;
  height: 55px;

  background: #0174cf;
  border: 0px;
  cursor: pointer;
  transition: all 0.5s;
}

.formFooter > .submitButton:hover {
  color: #0174cf;
  background: #ffffff;
  transition: 0.3s ease-in-out;
  border: 2px solid #0174cf;
  transition: all 0.5s;
}

.formFooterPayment {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-end;
}

.formFooterPayment > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(40px / 1.8), calc((5.8vw * 40) / 100));
  margin: 0px;
  text-transform: uppercase;

  color: #0174cf;
}

.formFooterPayment > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));

  text-transform: uppercase;

  color: #0174cf;
}

.formFooterPayment > input {
  margin: 0px 20px 0px 20px;
}

.formFooterPayment > .submitButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  margin: 0px;
  text-transform: uppercase;

  color: #ffffff;

  width: 270px;
  height: 55px;

  background: #0174cf;
  border: 0px;
  cursor: pointer;
  transition: all 0.5s;
}

.formFooterPayment > .submitButton:hover {
  color: #0174cf;
  background: #ffffff;
  transition: 0.3s ease-in-out;
  border: 2px solid #0174cf;
  transition: all 0.5s;
}

.responsiveInLine {
  display: flex;
}

.bigTitle {
  margin: 0;
  position: absolute;
  bottom: 20%;
  left: 5%;

  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-size: max(calc(96px / 1.8), calc((5.8vw * 96) / 100));
  line-height: 10vw;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}

.counter > div > input {
  text-align: center;
  padding: 15px 5px 15px 5px;
}

@media only screen and (max-width: 768px) {
  .form {
    margin: auto;
    padding: 0;
    width: 90%;
  }
  .form > input,
  .form > input::placeholder {
    font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  }

  .form > div > input,
  .form > div > input::placeholder {
    font-size: max(calc(16px / 1.8), calc((5.8vw * 16) / 100));
  }
  .responsiveInLine > input {
    margin: 0;
  }

  .responsiveInLine {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .counter {
    flex-direction: column;
    align-items: center;
  }
  .counter > div > input {
    padding: 0px;
    margin: 0px;
    text-align: center;
  }

  .formFooter {
    flex-direction: column;
    align-items: center;
  }

  .formFooterPayment {
    flex-wrap: wrap;
  }
  .formFooterPayment > input:nth-child(1) {
    margin: 0px 0px 20px 0px;
  }
  .formFooterPayment > input:nth-child(2) {
    margin: 0px 0px 20px 0px;
  }

  .formFooterPayment > input:nth-child(3) {
    width: 100% !important;
  }

  .input {
    margin: 20px 20px 0px 20px;
  }

  .row {
    /* flex-direction: column; */
  }
}
