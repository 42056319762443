.link,
.link > div > span {
  font-family: "Roboto Slab" !important;
  font-style: normal !important;
  font-weight: 400 !important;

  font-size: max(calc(24px / 1.8), calc((5.8vw * 24) / 100)) !important;

  line-height: 32px !important;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.footerLink,
.footerLink > div > span {
  font-family: "Roboto Slab" !important;
  font-style: normal !important;
  font-weight: 200 !important;

  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100)) !important;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.footerTitle {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 200;

  font-size: max(calc(36px / 1.8), calc((5.8vw * 36) / 100)) !important;
  line-height: 47px;
  text-align: left;
  text-transform: uppercase;

  color: #ffffff;
}
.footerInput {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  margin-top: 40px;
  height: 62px;

  color: white;
  font-family: Inter;

  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100));
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
}

.subscribeBtn {
  width: 235px !important;
  height: 62px !important;

  font-size: max(calc(20px / 1.8), calc((5.8vw * 20) / 100)) !important;
  font-weight: 400 !important;

  letter-spacing: 0em !important;
  text-align: center !important;

  font-family: "Inter" !important;
  font-style: normal !important;

  display: flex;
  align-items: center;

  text-transform: uppercase;

  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .footerTitle {
    margin-top: 50px !important;
  }
}
